// @flow

import React from "react";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/nl";

import { Auth, Form } from "../components";

/**
 * Homepage
 */
const IndexPage = () => (
    <MuiPickersUtilsProvider
        utils={MomentUtils}
        moment={moment}
        language={"nl"}
    >
        <Auth>{profile => <Form profile={profile} freelance />}</Auth>
    </MuiPickersUtilsProvider>
);

export default IndexPage;
